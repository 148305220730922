import { render, staticRenderFns } from "./lvl-calculator.vue?vue&type=template&id=cb5b9672&scoped=true&"
import script from "./lvl-calculator.vue?vue&type=script&lang=js&"
export * from "./lvl-calculator.vue?vue&type=script&lang=js&"
import style0 from "./lvl-calculator.vue?vue&type=style&index=0&id=cb5b9672&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb5b9672",
  null
  
)

export default component.exports