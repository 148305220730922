<template>
  <div>
    <section class="section" style="padding-top: 0px;">
      <div class="container Nunito" style="padding: 0;">
        <section class="section headerSection" style="padding-top: 20px;">
          <div class="container has-text-centered">
            <h1 class="title is-2 noselect">Level Calculator!</h1>
            <h1 style="margin-bottom:0px" class="subtitle has-text-white is-5 noselect">Trying to figure out how long it will take to level up?</h1>
            <h1 style="margin-bottom:0px" class="subtitle has-text-white is-6 noselect">Use our Level Calculator to figure it out!</h1>
          </div>
        </section>
        <section class="section" style="padding: 0;">
          <div class="columns">
            <div class="column is-centered has-shadow is-two-thirds LEVELOP">
              <h1 class="title">Enter Level Options:</h1>
              <h2 class="subtitle has-text-grey">Each level is 500 xp times the level. (1 * 500 = 500 xp required)</h2>
              <div class="columns">
                <div class="column">
                  <b-field class="hovermessagefeild" position="is-centered" label="Level">
                    <b-numberinput :min="0" type="is-info" :controls="true" v-model="level"></b-numberinput>
                  </b-field>
                </div>
                <div class="column">
                  <b-field class="hovermessagefeild" position="is-centered" label="Already Gained Xp (for Level not Total)">
                    <b-numberinput :min="0" type="is-info" :controls="true" v-model="totalXP"></b-numberinput>
                  </b-field>
                </div>
              </div>
              <h1 class="title" style="padding-top: 10px;">Extra Options:</h1>
              <h2 class="subtitle has-text-grey" style="margin-bottom: 0.9rem">These are extra options simply for the UI.</h2>
              <div class="columns">
                <div class="column">
                  <b-checkbox v-model="abbreviateNum" type="is-info">Abbreviate Number's</b-checkbox>
                  <br>
                  <b-checkbox v-model="roundMSG" type="is-info">Round Message's</b-checkbox>
                </div>
              </div>
            </div>
            <div class="column is-centered has-shadow XPOP">
              <h1 class="title">Enter Xp Options:</h1>
              <div class="columns">
                <div class="column">
                  <b-field class="hovermessagefeild" position="is-centered" label="Min Xp">
                    <b-numberinput :min="0" type="is-info" :controls="false" v-model="minXP"></b-numberinput>
                  </b-field>
                </div>
                <div class="column">
                  <b-field class="hovermessagefeild" position="is-centered" label="Max Xp">
                    <b-numberinput :min="0" type="is-info" :controls="false" v-model="maxXP"></b-numberinput>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <label class="label">Xp Type</label>
                  <b-radio v-model="type" type="is-info" :native-value="'text'">
                    Channel Xp
                  </b-radio>
                  <b-radio v-model="type" type="is-info" :native-value="'voice'">
                    Voice Xp
                  </b-radio>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <label class="label">Cooldown</label>
                  <b-radio v-model="cooldown" type="is-info" :native-value="60">
                    60 seconds
                  </b-radio>
                  <b-radio v-model="cooldown" type="is-info" :native-value="45">
                    45 seconds
                  </b-radio>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <label class="label">Multiplier's</label>
                  <b-checkbox v-model="premium" type="is-info">Premium User/Server (x2)</b-checkbox>
                  <b-checkbox v-model="voted" type="is-info">User Voted via TOP.GG (x1.5)</b-checkbox>
                  <b-checkbox v-model="staffTest" type="is-info">Staff Testing (x2.4)<br>(Requires Manual Enable via Bot Staff)</b-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="columns" style="margin-top: 30px;">
            <div class="column is-centered has-shadow is-one-third TOTAL">
              <h1 class="title">Estimated Results:</h1>
              <h2 class="subtitle has-text-grey">These results estimates, results may vary.</h2>
              <div class="columns">
                <div class="column">
                  <label class="label">Required Xp</label>
                  <p>You will require <b class="mainTextColor" style="font-size: 17px;">{{requiredXPText}}</b> xp to level up.</p>
                </div>
                <div class="column">
                  <label class="label">Messages</label>
                  <p>Max: <b class="mainTextColor" style="font-size: 17px;">{{maxMSG}}</b></p>
                  <p>Average: <b class="mainTextColor" style="font-size: 17px;">{{avgMSG}}</b></p>
                  <p>Min: <b class="mainTextColor" style="font-size: 17px;">{{minMSG}}</b></p>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <label class="label">Required Time</label>
                  <p>It will take <b class="mainTextColor" style="font-size: 17px;">{{requiredTimeText}}</b> level up.</p>
                </div>
              </div>
            </div>
            <div class="column is-centered has-shadow is-one-third TOTAL-2">
              <h1 class="title">Did you know?</h1>
              <p>Purchasing premium for your server, you unblock more xp settings like min/max xp, x2 xp multiplier per message and more!</p>
              <p>Or by voting on TOP.GG you get x1.5 xp per message!</p>
              <div class="buttons" style="padding-top: 10px;">
                <b-button type="is-success" tag="router-link" to="/premium">Upgrade to premium</b-button>
                <b-button type="is-info" tag="router-link" to="/ref?ref=gracebot-cal">Vote via TOP.GG</b-button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>
<script>
  export default {
    name: 'lvl-calculator',
    data () {
      return {
        type: 'text',
        level: Number(this.$route.query.level) || 1,
        totalXP: Number(this.$route.query.xp) || 0,
        minXP: Number(this.$route.query.minXP) || 10,
        maxXP: Number(this.$route.query.maxXP) || 30,
        premium: (/true/i).test(this.$route.query.premium) || false,
        voted: (/true/i).test(this.$route.query.voted) || false,
        staffTest: (/true/i).test(this.$route.query.staffTest) || false,
        cooldown: 60,
        levelPer: 500,
        abbreviateNum: false,
        roundMSG: true
      }
    },
    components: {},
    async created () {
      this.cooldown = (Number(this.$route.query.cooldown) === 45 || this.premium ? 45 : null) || 60
    },
    computed: {
      levelAmt () {
        return (this.level * this.levelPer)
      },
      requiredTimeText () {
        let avgTime = '0min' // eslint-disable-line
        let minTime = '0min' // eslint-disable-line
        let maxTime = '0min' // eslint-disable-line
        return `${this.durationAsString((this.minMSG * this.cooldown) * 1000)} to ${this.durationAsString((this.maxMSG * this.cooldown) * 1000)} (avg ${this.durationAsString((this.avgMSG * this.cooldown) * 1000)})`
      },
      requiredXPText () {
        if (this.abbreviateNum) return this.abb(Number(this.levelAmt - this.totalXP))
        if (Math.sign(this.levelAmt - this.totalXP) === -1) return 0
        return (this.levelAmt - this.totalXP).toLocaleString('en')
      },
      requiredXP () {
        if (Math.sign(this.levelAmt - this.totalXP)) return 0
        return (this.levelAmt - this.totalXP)
      },
      maxMSG () {
        let xp = this.minXP
        if (this.premium) xp = xp * 2
        if (this.voted) xp = xp * 1.5
        if (this.staffTest) xp = xp * 2.4
        if (Math.sign(Math.round((this.levelAmt - this.totalXP) / xp)) === -1) return 0
        if (this.roundMSG && this.abbreviateNum) return this.abb(Math.round((this.levelAmt - this.totalXP) / xp))
        if (this.roundMSG) return Math.round((this.levelAmt - this.totalXP) / xp).toLocaleString('en')
        return ((this.levelAmt - this.totalXP) / xp).toLocaleString('en')
      },
      avgMSG () {
        let xp = ((this.minXP + this.maxXP) / 2)
        if (this.premium) xp = xp * 2
        if (this.voted) xp = xp * 1.5
        if (this.staffTest) xp = xp * 2.4
        if (Math.sign(Math.round((this.levelAmt - this.totalXP) / xp)) === -1) return 0
        if (this.roundMSG && this.abbreviateNum) return this.abb(Math.round((this.levelAmt - this.totalXP) / xp))
        if (this.roundMSG) return Math.round((this.levelAmt - this.totalXP) / xp).toLocaleString('en')
        return ((this.levelAmt - this.totalXP) / xp).toFixed(2).toLocaleString('en')
      },
      minMSG () {
        let xp = this.maxXP
        if (this.premium) xp = xp * 2
        if (this.voted) xp = xp * 1.5
        if (this.staffTest) xp = xp * 2.4
        if (Math.sign(Math.round((this.levelAmt - this.totalXP) / xp)) === -1) return 0
        if (this.roundMSG && this.abbreviateNum) return this.abb(Math.round((this.levelAmt - this.totalXP) / xp))
        if (this.roundMSG) return Math.round((this.levelAmt - this.totalXP) / xp).toLocaleString('en')
        return ((this.levelAmt - this.totalXP) / xp).toLocaleString('en')
      }
    },
    methods: {
      abb (n) {
        if (Math.sign(n) === -1 || ('' + n) === '-0.0') return 0
        if (n < 1e3) {
          return n
        }
        if (n >= 1e3 && n < 1e6) {
          return +(n / 1e3).toFixed(1) + 'K'
        }
        if (n >= 1e6 && n < 1e9) {
          return +(n / 1e6).toFixed(1) + 'M'
        }
        if (n >= 1e9 && n < 1e12) {
          return +(n / 1e9).toFixed(1) + 'B'
        }
        if (n >= 1e12) {
          return +(n / 1e12).toFixed(1) + 'T'
        }
      },
      durationAsString (ms, maxPrecission = 3) {
        const duration = this.$MomentJS.duration(ms)

        const items = []
        items.push({ timeUnit: (this.abbreviateNum ? 'd' : ' day'), value: Math.floor(duration.asDays()) })
        items.push({ timeUnit: (this.abbreviateNum ? 'h' : ' hour'), value: duration.hours() })
        items.push({ timeUnit: (this.abbreviateNum ? 'm' : ' minute'), value: duration.minutes() })
        items.push({ timeUnit: (this.abbreviateNum ? 's' : ' second'), value: duration.seconds() })

        const formattedItems = items.reduce((accumulator, { value, timeUnit }) => {
          if (value !== 0) accumulator.push(`${value}${(value >= 2 ? (this.abbreviateNum ? timeUnit : timeUnit + 's') : timeUnit)}`)
          return accumulator
        }, [])

        return formattedItems.length !== 0 ? formattedItems.join(' ') : '-'
      }
    }
  }
</script>

<style scoped>
  .has-shadow {
    box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.3) !important;
  }
  .radio:hover, .checkbox:hover {
    color: unset !important;
  }
  @media screen and (min-width: 1023px) {
    .XPOP {
      margin-left: 40px;
      margin-top: 0px !important;
    }
    .TOTAL-2 {
      margin-left: 20px;
      margin-top: 0px !important;
    }
    #level {
      width: 50%;
    }
  }
  .LEVELOP {
    background: var(--main-color-bg-3);
    border-radius: 10px;
    min-height: 200px;
  }
  .XPOP {
    background: var(--main-color-bg-3);
    border-radius: 10px;
    min-height: 200px;
    margin-top: 10px;
  }
  .TOTAL, .TOTAL-2 {
    background: var(--main-color-bg-3);
    border-radius: 10px;
    min-height: 200px;
  }
  .TOTAL-2 {
    margin-top: 10px;
  }
</style>
